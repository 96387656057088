import '../styles/globals.css';
import type { AppContext, AppProps } from 'next/app';
import '../styles/app.css';
import '../styles/async_fonts.css';
import '../styles/makaio.css';
import '../styles/globals.scss';

import { BaseDataLocalized } from '../src/types';
import React from 'react';
import { SpSiteData } from '../src/providers/SiteDataProvider';

declare global {
    interface Window {
        spSiteData: SpSiteData;
    }
}

const MyApp = (props: AppProps<BaseDataLocalized>) => {
    const { Component, pageProps } = props;
    if (typeof window !== 'undefined') {
        const typedWindow = window as unknown as {
            spSiteData: SpSiteData;
        };
        const { spName, apiUrl, baseUrl, language, isProduction } = pageProps;
        if (typedWindow.spSiteData?.spName !== spName) {
            typedWindow.spSiteData = {
                apiUrl: apiUrl || baseUrl,
                baseUrl,
                spName,
                defaultLanguage: language,
                isProduction,
            };
            console.log('updated spSiteData', typedWindow.spSiteData);
        }
    }

    return <Component {...pageProps} />;
};

export default MyApp;
